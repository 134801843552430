.PubTokenPageArea {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}
.PubTokenPageArea .home_v1 {
  display: none;
}
.PubTokenPageArea .home_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.PubTokenPageArea .home_header .home_header_search {
  width: 100%;
  height: 38px;
}
.PubTokenPageArea .home_header .home_header_search .home_header_search_input {
  width: 100%;
  height: 100%;
}
.PubTokenPageArea .home_header .home_header_search .home_header_search_input .ant-input-wrapper {
  width: 100%;
  height: 100%;
}
.PubTokenPageArea .home_header .home_header_search .home_header_search_input .ant-input-wrapper .ant-input-affix-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #b482ff;
  font-size: 16px;
  box-shadow: none;
}
.PubTokenPageArea .home_header .home_header_search .home_header_search_input .ant-input-wrapper .ant-input-affix-wrapper:active {
  box-shadow: none;
}
.PubTokenPageArea .home_header .home_header_search .home_header_search_input .ant-input-wrapper .ant-input-affix-wrapper .ant-input::placeholder {
  color: #999;
}
.PubTokenPageArea .home_header .home_header_search .home_header_search_input .ant-input-wrapper .ant-input-group-addon {
  display: none;
}
.PubTokenPageArea .home_header .home_header_select {
  width: 188px;
  height: 38px;
  margin-left: 1rem;
  border: 1px solid #A020F0;
  background-color: #A020F0;
  border-radius: 6px;
}
.PubTokenPageArea .home_header .home_header_select .home_header_select_input {
  width: 100%;
  height: 100%;
}
.PubTokenPageArea .home_header .home_header_select .home_header_select_input:active .ant-select-selector,
.PubTokenPageArea .home_header .home_header_select .home_header_select_input:hover .ant-select-selector {
  border: none;
  box-shadow: none;
  color: #fff;
}
.PubTokenPageArea .home_header .home_header_select .home_header_select_input .ant-select-selector {
  min-width: 10rem;
  margin-top: 0.1rem;
  margin-right: 3rem;
  background: transparent;
  border: none;
  color: #fff;
  text-align: center;
  box-shadow: none;
}
.PubTokenPageArea .home_header .home_header_select .home_header_select_input .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 0;
}
.PubTokenPageArea .home_header .home_header_select .home_header_select_input .ant-select-arrow {
  transition: all 0.3s;
  margin-left: 1rem;
  color: white;
}
.PubTokenPageArea .home_header .home_header_select .ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}
.PubTokenPageArea .home_header .home_header_select .ant-select-single.ant-select-open .ant-select-selection-item {
  color: white;
}
.PubTokenPageArea .list {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  gap: 30px;
}
.PubTokenPageArea .list_column {
  display: flex;
  flex-direction: column;
  background-clip: padding-box;
  gap: 30px;
}
.PubTokenPageArea .home_more {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  height: 50px;
}
.PubTokenPageArea .home_more .home_more_button {
  width: 220px;
  height: 50px;
  font-family: PingFang SC, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  border-radius: 10px;
  border: none;
  background-color: #6001ff;
}
.PubTokenPageArea .home_more .home_more_button:hover,
.PubTokenPageArea .home_more .home_more_button:active {
  background: #6819ec;
}
.home_header_select_popup {
  background-color: rgba(21, 21, 39, 0.8);
  text-align: center;
  border: 1px solid #A020F0;
  box-shadow: 0 4px 20px #1e1a39;
  backdrop-filter: blur(10px);
}
.home_header_select_popup .ant-select-item-option {
  color: #fff;
}
.home_header_select_popup .ant-select-item-option-selected {
  background-color: transparent !important;
  color: #A020F0 !important;
}
@media screen and (max-width: 768px) {
  .PubTokenPageArea {
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .PubTokenPageArea .home_v1 {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 1.5rem;
  }
  .PubTokenPageArea .home_v1 .home_v1_btn {
    width: 100%;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #A020F0;
    background-color: #A020F0;
    border-radius: 6px;
    color: #fff;
  }
  .home .home_header .home_header_search {
    width: 100%;
    height: 38px;
  }
  .home .home_header .home_header_search .home_header_search_input .ant-input-wrapper .ant-input-affix-wrapper {
    font-size: 14px;
  }
  .home .home_header .home_header_select {
    width: 150px;
  }
  .home .home_header .home_header_select .home_header_select_input .ant-select-selector {
    padding: 0 5px;
  }
  .home .home_header .home_header_select .home_header_select_input .ant-select-selector .ant-select-selection-search {
    inset-inline-start: 5px;
    inset-inline-end: 5px;
  }
  .home .home_header .home_header_select .home_header_select_input .ant-select-arrow {
    inset-inline-end: 3px;
  }
  .home .list {
    width: 100%;
    gap: 0;
    justify-content: space-between;
  }
  .home .list_column {
    width: 48% !important;
    gap: 10px;
  }
  .home .home_more {
    height: 40px;
  }
  .home .home_more .home_more_button {
    width: 200px;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
  }
}
