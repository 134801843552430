.list_item {
  width: 100%;
  height: auto;
  color: black;
  background: white;
  border: 1px solid rgba(96, 1, 255, 0.4);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.list_item:hover {
  border: 1px solid #6001ff;
}
.list_item .netImgDiv {
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  justify-content: end;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}
.list_item .netImgDiv .net-image {
  width: 1rem;
  border-radius: 990px;
  align-items: end;
}
.list_item .imgDiv {
  padding-top: 0.2rem;
  padding-bottom: 2rem;
}
.list_item .imgDiv .img {
  width: 8rem;
}
.list_item .imgDiv .img > img {
  width: 8rem;
  border-radius: 990px;
}
.list_item .imgDiv .img .increase {
  position: absolute;
  top: 20px;
  right: 0;
  background: #35fffe;
  padding: 0 10px;
  height: 20px;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  border-radius: 10px 0 0 10px;
}
.list_item .info {
  width: 100%;
  padding: 16px;
}
.list_item .info .base-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.list_item .info .base-info .name {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 0.1rem;
}
.list_item .info .base-info .symbol {
  font-size: 14px;
  font-weight: 500;
}
.list_item .info .base-info .price {
  font-size: 14px;
  color: #878d99;
  margin-top: 1rem;
}
.list_item .info .desc {
  margin-top: 2rem;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 0;
  word-wrap: normal;
  word-break: break-all;
}
.list_item .info .marketcap {
  margin-top: 20px;
}
.list_item .info .marketcap .price {
  font-size: 14px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}
