.overview_bg_img {
  width: 100%;
  height: 140px;
}
.Overview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.Overview .title {
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 50px;
}
.Overview .title-version {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 80px;
}
.Overview .contents {
  width: 100%;
  margin-bottom: 25px;
}
.Overview .contents .space_big {
  margin-top: 50px;
}
.Overview .contents .space_normal {
  margin-top: 25px;
}
@media screen and (max-width: 768px) {
  .overview_bg_img {
    width: 100%;
    height: 60px;
  }
  .Overview {
    color: white;
  }
}
