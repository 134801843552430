.common_btn {
    margin-left: 0.2rem;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background: #5284ff;
    color: #fff;
}

.disable_btn {
    margin-left: 0.2rem;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: rgb(128, 128, 128);
}

.w-full{
    width: 100%;
}

.body-content{
    width: 1000px;
}

hover-item {
    cursor: pointer;
    color:red;
}

.one-row{
    display: flex;
    justify-content: center;
    align-items: center;
}

.common_copy_btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    background: #5284ff;
    color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 18px;
    color:black;
}

.footer_area{
    width: 100%;
}

.mainBody{
    background-color: white;
}

.common_user-avater{
    width: 6rem;
    height: 6rem;
    border-radius: 9999px
}

.x20_comm_btn {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background: #A020F0;
    color: #fff;
}

.ant-table{
    .ant-table-container{
        border-radius: 10px;
        overflow: auto;
        border: 1px solid #0e2c46;
        .ant-table-content{
            table{
                background-color: #fff;
                .ant-table-thead{
                    background-color: #0e2c46;
                    overflow: auto;
                    color: #fff;
                    tr{
                        th{
                            text-align: start;
                            background-color: transparent;
                            color: #fff;
                        }
                    }
                }

                .ant-table-tbody{
                    .ant-table-row{
                        .ant-table-cell{
                            text-align: start;
                            .table_copy{
                                display: flex;
                                text-align: start;
                                color: blue;
                                flex-direction: column;
                                cursor: pointer;
                            }
                            .column-token-name{
                                display: flex;
                                text-align: start;
                                color: blue;
                                flex-direction: column;
                                cursor: pointer;
                                width: 140px;
                            }
                            .column-wid-80{
                                min-width: 80px;
                            }
                            .table_time{
                                display: flex;
                                text-align: start;
                            }
                        }
                    }
                    tr{
                        td{
                            text-align: start;
                            border:none;
                        }
                    }
                }
            }
        }
    }
}

.ant-table-wrapper{
    .ant-spin-nested-loading{
        .ant-spin-container{
            .ant-table{
                .ant-table-container{
                    .ant-table-content{
                        .table_time{

                        }
                        .table_copy{
                            color: blue;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            cursor: pointer;
                            .copy_btn{
                            }
                            .to_withdraw{
                            }
                        }
                        .table_btns{
                            display: flex;
                            flex-direction: column;
                            a{
                                color: blue;
                            }
                            .disable_btn{
                                color: gray;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color:white;
        font-size: 16px;
    }

    .mainBody{
        background-color: #0b002a;
    }

    .footer_area{
        background-color: #0b002a;
        color: white;
    }
}
