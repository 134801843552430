.PubToken {
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  color: black;
}
.PubToken .mutirow {
  display: flex;
}
.PubToken .mutirow .ant-upload-picture-card-wrapper {
  margin-top: 0.2rem;
  width: 175px;
}
.PubToken .mutirow .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
  width: 175px;
  height: 175px;
  margin-bottom: 0;
  margin-right: 0;
}
.PubToken .mutirow .right {
  margin-left: 10px;
  width: 90%;
}
.PubToken .linerow {
  text-align: start;
  font-weight: 700;
}
.PubToken .linerow .row-top {
  display: flex;
  flex-direction: row;
}
.PubToken .linerow .towtitle {
  margin-bottom: 0.4rem;
}
.PubToken .linerow .rowinput {
  margin-bottom: 0.3rem;
}
.PubToken .linerow .rowinput .ant-switch {
  border: 1px solid #d9d9d9;
  border-radius: 40px;
  display: flex;
  align-items: center;
  width: 200px;
}
.PubToken .linerow .rowinput .ant-switch .ant-switch-inner {
  color: black;
}
.PubToken .linerow .rowinput .ant-switch .ant-switch-handle {
  top: 1px;
}
.PubToken .linerow .rowinput .ant-switch .ant-switch-handle::before {
  background-color: #A020F0;
}
.PubToken .linerow .rowinput .ant-switch-checked {
  justify-content: right;
}
@media screen and (max-width: 768px) {
  .PubToken {
    color: white;
    margin-top: 0.2rem;
  }
  .PubToken .mutirow {
    display: flex;
    flex-direction: column;
  }
  .PubToken .mutirow .ant-upload-picture-card-wrapper {
    width: 166px;
  }
  .PubToken .mutirow .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
    width: 166px;
    height: 166px;
  }
  .PubToken .mutirow .right {
    margin-left: 0px;
    width: 100%;
  }
  .PubToken .linerow .row-top {
    flex-direction: column;
  }
  .PubToken .linerow .rowinput .ant-switch {
    width: 100%;
  }
  .PubToken .linerow .rowinput .ant-switch .ant-switch-inner {
    color: white;
  }
}
