.ant-table-container {
  min-height: 24rem;
}
.table_user {
  display: flex;
  align-items: center;
}
.table_user .ant-image {
  margin-right: 0.3rem;
}
.PubTokenInfoDiv {
  width: 100%;
  min-height: 65vh;
  padding-bottom: 0.2rem;
  position: relative;
  margin: 0 auto;
  margin-top: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.PubTokenInfoDiv .fast-border {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.PubTokenInfoDiv .fast-border .fast-item {
  width: 49%;
  height: auto;
  padding: 1rem;
  border: 1px solid #0e2c46;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PubTokenInfoDiv .fast-border .fast-item .title {
  font-size: 1.125rem;
  font-weight: 700;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .rounded-full {
  width: 8rem;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .base-info {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .base-price-info {
  width: 100%;
  color: #878d99;
  text-align: center;
  margin-bottom: 2rem;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info {
  margin-top: 0.5rem;
  display: flex;
  align-items: end;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-text {
  width: 400px;
  /* Set the desired width */
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
  overflow: hidden;
  /* Hide overflowed content */
  text-overflow: ellipsis;
  direction: ltr;
  display: flex;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-text .name {
  display: flex;
  align-items: center;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-text .value {
  display: flex;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-desc {
  width: 540px;
  /* Set the desired width */
  direction: ltr;
  display: flex;
  font-size: 10px;
  color: gray;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-btn {
  width: 3rem;
  display: flex;
  align-items: baseline;
  justify-content: end;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-btn .copy-btn {
  margin-left: 0.5rem;
  width: 1.2rem;
  height: 100%;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-btn .copy-btn:hover {
  cursor: pointer;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .progress-info {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .progress-info .detail-btn {
  margin-left: 1rem;
}
.PubTokenInfoDiv .fast-border .fast-item .do-btn {
  margin-top: 0.625rem;
  width: 100%;
}
.PubTokenInfoDiv .fast-border .fast-item .user-row-title {
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 800;
}
.PubTokenInfoDiv .fast-border .fast-item .user-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
  color: black;
}
.PubTokenInfoDiv .fast-border .fast-item .user-row .row-left {
  display: flex;
}
.PubTokenInfoDiv .fast-border .fast-item .user-row .row-left .user-avater {
  width: 2rem;
  height: 2rem;
}
.PubTokenInfoDiv .fast-border .fast-item .user-row .row-left .left-info {
  margin-left: 0.6rem;
  font-size: 0.9rem;
  font-weight: 400;
}
.PubTokenInfoDiv .fast-border .fast-space {
  width: 1rem;
}
.PubTokenInfoDiv .fast-border .space-left {
  margin-left: 2%;
}
.PubTokenInfoDiv .handle-btn-div .text {
  margin-top: 1.5rem;
}
.PubTokenInfoDiv .handle-btn-div .do-btn {
  margin-top: 1.5rem;
}
.PubTokenInfoDiv .handle-btn-div .total-info {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.PubTokenInfoDiv .social-info {
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid #0e2c46;
  border-radius: 0.625rem;
}
.PubTokenInfoDiv .social-info .base {
  display: flex;
  justify-content: space-between;
}
.PubTokenInfoDiv .social-info .base .item {
  margin-top: 0.5rem;
  display: flex;
  font-size: 12px;
}
.PubTokenInfoDiv .social-info .desc {
  width: 100%;
  word-wrap: normal;
  word-break: break-all;
  padding: 10px 0;
}
.PubTokenInfoDiv .history {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.PubTokenInfoDiv .history .withdraw-info {
  margin-bottom: 0.8rem;
}
.PubTokenInfoDiv .history .pageDiv {
  display: flex;
  justify-content: end;
  margin-top: 0.4rem;
}
.PubTokenInfoDiv .history .history_t {
  font-weight: bold;
}
.PubTokenInfoDiv .history .table-list {
  width: 100%;
  margin-top: 10px;
}
.PubTokenInfoDiv .history .table-list .table_user {
  text-align: center;
  display: flex;
  justify-content: center;
}
.CopyModal {
  font-size: 22px;
}
.CopyModal .ModalTitle {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.CopyModal .ModalContent {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.CopyModal .ModalBtns {
  display: flex;
  flex-direction: row;
}
.CopyModal .ModalBtns .OneBtn {
  width: 48%;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: white;
}
.CopyModal .ModalBtns .YesBtn {
  background: #5284ff;
  margin-left: 2%;
}
.CopyModal .ModalBtns .CancelBtn {
  background: gray;
}
@media screen and (max-width: 768px) {
  .table_btns {
    display: none;
  }
  .search-area {
    margin: 10px 0px;
    width: 100%;
    font-size: 18px;
  }
  .search-area .search-form {
    padding: 0 5px;
  }
  .search-area .earch_button {
    width: 80px;
    margin-left: 5px;
  }
  .PubTokenInfoDiv {
    width: 100%;
    padding: 0 20px;
    padding-top: 50px;
    min-height: 70vh;
  }
  .PubTokenInfoDiv .fast-border .space-left {
    margin-left: 0px;
  }
  .PubTokenInfoDiv .fast-border .fast-item {
    width: 100%;
  }
  .PubTokenInfoDiv .fast-border .fast-item .box_logo {
    width: 100%;
  }
  .PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div {
    width: 100%;
  }
  .PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info {
    width: 100%;
  }
  .PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info .contact-text {
    flex-direction: column;
    width: 100%;
  }
  .PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info2 {
    width: 100%;
  }
  .PubTokenInfoDiv .fast-border .fast-item .box_logo .contact-div .contact-info2 .contact-text {
    flex-direction: column;
    width: 100%;
  }
  .PubTokenInfoDiv .handle-btn-div {
    color: white;
    margin-bottom: 1.5rem;
  }
  .PubTokenInfoDiv .handle-btn-div .do-btn {
    margin-top: 0rem;
  }
  .PubTokenInfoDiv .social-info .desc {
    color: white;
  }
}
