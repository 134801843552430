.normal_handle_btn_disable {
  margin-left: 0.2rem;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e5e5e5;
  border: 1px solid #333;
}
.create_btn {
  width: 100%;
  margin-left: 0.2rem;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
  margin-bottom: 4rem;
}
.ant-select-selector {
  height: 100%;
}
.table_btns {
  width: 120px;
}
.sub_title {
  font-weight: bold;
}
.create_dumb_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.create_dumb_area .reg_item_row {
  margin-top: 30px;
}
.create_dumb_area .reg_item_row_div {
  margin-top: 40px;
  margin-bottom: 40px;
}
.create_dumb_area .reg_item_row_div .reg_item_row_btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.create_dumb_area .search-form {
  flex: 1;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 0px 20px;
}
.create_dumb_area .search-form input {
  padding-left: 10px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: Overpass;
  font-weight: 100;
}
.create_dumb_area .search-form input:focus {
  outline: 0;
}
.create_dumb_area .search-form input::-webkit-input-placeholder {
  color: gray;
}
.create_dumb_area .search-form .end {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.create_dumb_area .earch_button {
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  width: 162px;
  border: none;
  overflow: hidden;
}
.create_dumb_area .earch_button:hover {
  cursor: pointer;
}
.ensset_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 40px;
}
.ensset_area .reg_item_row {
  margin-top: 30px;
}
.ensset_area .reg_item_row_div {
  margin-top: 40px;
  margin-bottom: 40px;
}
.ensset_area .reg_item_row_div .reg_item_row_btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.ensset_area .search-form {
  flex: 1;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 0px 20px;
}
.ensset_area .search-form input {
  padding-left: 10px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: Overpass;
  font-weight: 100;
}
.ensset_area .search-form input:focus {
  outline: 0;
}
.ensset_area .search-form input::-webkit-input-placeholder {
  color: gray;
}
.ensset_area .search-form .end {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.ensset_area .earch_button {
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  width: 162px;
  border: none;
  overflow: hidden;
}
.ensset_area .earch_button:hover {
  cursor: pointer;
}
.btn_area {
  margin-top: 70px;
}
.MyTokenList {
  width: 100%;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
}
.MyTokenList .ens_title {
  text-align: center;
  margin-top: 1.25rem;
  font-weight: 700;
}
.MyTokenList .ens_title p {
  margin-bottom: 0.1rem;
}
.MyTokenList .title {
  margin-bottom: 10px;
  font-weight: bold;
}
.MyTokenList .did_reg_div {
  width: 100%;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid #333;
}
.MyTokenList .did_reg_div .area_row_input {
  display: flex;
  margin-top: 0.2rem;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
.MyTokenList .did_reg_div .area_row_input .modal_input {
  display: flex;
  flex: 1;
  margin-left: 0.2rem;
  border: 0.01rem solid gray;
  height: 40px;
  line-height: 40px;
  border-radius: 0.1rem;
  background-color: white;
  color: black;
  padding-left: 0.2rem;
}
.MyTokenList .did_reg_div .area_row_input .fee_btn {
  width: 0.6rem;
}
.MyTokenList .did_reg_div .area_row_input .modal_progress {
  margin-left: 0.1rem;
}
.MyTokenList .did_reg_div .area_check_view .title {
  margin-top: 0.3rem;
  display: flex;
  height: 40px;
  line-height: 40px;
}
.MyTokenList .did_reg_div .area_check_view .row {
  display: flex;
  margin-top: 0.2rem;
  margin-left: 1rem;
  line-height: 24px;
}
.MyTokenList .did_reg_div .area_check_view .row .minWidth {
  width: 180px;
}
.MyTokenList .did_reg_div .area_check_view .row .modal_progress {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.2rem;
}
.MyTokenList .inviteArea {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MyTokenList .inviteArea .pub_btn {
  margin-top: 0.5rem;
}
.MyTokenList .history {
  width: 100%;
  margin-top: 2px;
}
.MyTokenList .history .type-title {
  margin-top: 8px;
}
.MyTokenList .history .pageDiv {
  display: flex;
  justify-content: end;
  margin-top: 2rem;
}
.MyTokenList .history .history_t {
  font-weight: bold;
}
.MyTokenList .history .table-list {
  width: 100%;
  margin-top: 10px;
}
.MyTokenList .air_invite_div {
  margin-top: 50px;
  width: 100%;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid #333;
}
.MyTokenList .air_invite_div .copy {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MyTokenList .air_invite_div .copy .copy_btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  background: #5284ff;
  color: white;
}
.MyTokenList .no_reg_div {
  margin-top: 50px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}
.MyTokenList .no_reg_div .no_reg_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MyTokenList .no_reg_div .no_reg_top .text {
  flex: 1;
  padding-right: 50px;
}
.MyTokenList .no_reg_div .no_reg_top .btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  background: #5284ff;
  color: white;
  cursor: pointer;
}
.MyTokenList .no_reg_div .error_msg {
  margin-top: 20px;
}
.rule_modal_box .ant-modal-content .ant-modal-body .title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_type {
  margin-bottom: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_year {
  margin-bottom: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_price {
  margin-bottom: 0.5rem;
}
.CopyModal {
  font-size: 22px;
}
.CopyModal .ModalTitle {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.CopyModal .ModalContent {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.CopyModal .ModalBtns {
  display: flex;
  flex-direction: row;
}
.CopyModal .ModalBtns .OneBtn {
  width: 48%;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: white;
}
.CopyModal .ModalBtns .YesBtn {
  background: #5284ff;
  margin-left: 2%;
}
.CopyModal .ModalBtns .CancelBtn {
  background: gray;
}
@media screen and (max-width: 768px) {
  input {
    color: #000000;
  }
  .MyTokenList {
    width: 100%;
    padding: 0 20px;
    padding-top: 50px;
    background-color: #0b002a;
    color: white;
  }
  .MyTokenList .ens_title {
    color: white;
  }
  .MyTokenList .title {
    color: white;
  }
  .MyTokenList .did_reg_div {
    color: white;
    padding: 16px;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area {
    margin: 10px 0px;
    width: 100%;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area .reg_item_row .vote_year .ant-radio-group .ant-radio-wrapper span {
    color: white;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area .reg_item_row_div {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area .reg_item_row_div .reg_item_row_btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area .reg_item_row_div .reg_item_row_btns .ens_reg_url {
    margin-top: 10px;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area .search-form {
    padding: 0 5px;
  }
  .MyTokenList .did_reg_div .new_nft .ensset_area .earch_button {
    width: 130px;
    margin-left: 5px;
  }
  .MyTokenList .did_reg_div .title {
    color: white;
  }
  .MyTokenList .inviteArea {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: start;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .MyTokenList .history {
    overflow: hidden;
  }
  .MyTokenList .history .history_t {
    color: #fff;
  }
  .MyTokenList .air_invite_div {
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .MyTokenList .air_invite_div .title {
    color: rgba(0, 0, 0, 0.85);
  }
  .MyTokenList .air_invite_div .copy {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .MyTokenList .air_invite_div .copy .invite_link {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    word-break: break-word;
  }
  .MyTokenList .air_invite_div .copy .copy_btn {
    width: auto;
    padding: 0 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
    background-color: #fff;
  }
  .MyTokenList .no_reg_div {
    padding: 20px 10px;
    background-color: white;
  }
  .MyTokenList .no_reg_div .no_reg_top .text {
    padding-right: 1.25rem;
  }
  .MyTokenList .no_reg_div .no_reg_top .btn {
    width: 5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
  }
  .table_btns {
    display: none;
  }
  .ant-table {
    background-color: #0b002a;
  }
}
