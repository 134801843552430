.PubRankingArea {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}
.PubRankingArea .rankingTable {
  width: 100%;
  margin: 0 auto;
  display: flex;
}
.PubRankingArea .rankingTable .ant-table-wrapper {
  width: 100%;
}
.PubRankingArea .contributors-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PubRankingArea .home_more {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  height: 50px;
}
.PubRankingArea .home_more .home_more_button {
  width: 220px;
  height: 50px;
  font-family: PingFang SC, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  border-radius: 10px;
  border: none;
  background-color: #6001ff;
}
.PubRankingArea .home_more .home_more_button:hover,
.PubRankingArea .home_more .home_more_button:active {
  background: #6819ec;
}
@media screen and (max-width: 768px) {
  .PubRankingArea {
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
